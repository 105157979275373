export const state = () => ({
  recommendations: [],
  baseCustomizationUrl: '',
})

export const mutations = {
  SET_RECOMMENDATIONS(state, recommendations) {
    state.recommendations = recommendations
  },
  SET_BASE_CUSTOMIZATION_URL(state, baseCustomizationUrl) {
    state.baseCustomizationUrl = baseCustomizationUrl
  },
}

export const actions = {
  async initializeStore(
    { commit, dispatch },
    { baseCustomizationUrl, productId }
  ) {
    commit('SET_BASE_CUSTOMIZATION_URL', baseCustomizationUrl)

    await dispatch('fetchRecommendations', productId)
  },
  async fetchRecommendations({ commit }, productId) {
    // TODO: fix catalog v2 CORS issue
    try {
      const payload = await this.$axios.$get(
        `cors_anywhere/${this.$config.catalogV2ApiURL}/api/v1/public/recommendations/${productId}?store=br`
      )

      commit('SET_RECOMMENDATIONS', payload.result)
    } catch (error) {}
  },
  resetStore({ commit }) {
    commit('SET_RECOMMENDATIONS', [])
    commit('SET_BASE_CUSTOMIZATION_URL', '')
  },
}

export const getters = {
  recommendations: (state) => state.recommendations,
  baseCustomizationUrl: (state) => state.baseCustomizationUrl,
}
