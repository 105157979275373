<template>
  <div class="promo-event-bar">
    <div
      :style="progressStyle"
      class="promo-event-bar__progress"
      :class="[
        'promo-event-bar__progress',
        {
          'promo-event-bar__progress--blackfriday': $store.state.isBlackFriday,
          'promo-event-bar__progress--christmas': $store.state.isChristmas,
        },
      ]"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'PromoEventBar',
  props: {
    progress: {
      type: [Number, String],
      default: 0,
    },
  },
  computed: {
    progressStyle() {
      return {
        width: (Number(this.progress) <= 100 ? this.progress : 100) + '%',
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.promo-event-bar {
  position: relative;
  height: 4px;
  background: $v4_color_line;
  border-radius: 2px;
}
.promo-event-bar__progress {
  position: relative;
  overflow: hidden;
  height: 100%;
  border-radius: 2px;
  background-color: $v4_color_primary;

  &--blackfriday {
    background-color: $black_friday_color_primary;
  }

  &--christmas {
    background-color: $christmas_color_secondary;
  }
}
</style>
