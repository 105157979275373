<template>
  <div :class="cssClasses">
    <div class="featured-nav-item__left">
      <icon-power v-if="isLeftFeatured" />
      <icon-gift v-else />
    </div>

    <div class="featured-nav-item__right">
      {{ name() }}
    </div>
  </div>
</template>

<script>
import menulable from '~/mixins/menulable'
import IconPower from '~/components/icons/power/Power'
import IconGift from '~/components/icons/gift-box/GiftBox'

export default {
  components: {
    IconPower,
    IconGift,
  },
  mixins: [menulable],
  props: {
    item: {
      type: Object,
      required: true,
    },
    isLeftFeatured: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cssClasses() {
      let klass = `featured-nav-item ${this.isLeftFeatured ? 'sale' : ''}`
      if (!this.isLeftFeatured) {
        klass += ' featured-nav-item--secondary'
      }
      if (this.$store.state.isBlackFriday) {
        klass += ' featured-nav-item--blackfriday'
      }
      if (this.$store.state.isChristmas) {
        klass += ' featured-nav-item--christmas'
      }
      return klass
    },
  },
}
</script>

<style lang="scss" scoped>
.featured-nav-item {
  display: flex;
  align-items: center;

  .featured-nav-item__left {
    svg {
      fill: #567c7e !important;
    }
  }

  .featured-nav-item__left {
    flex-grow: 0;
    border-radius: 50%;
    width: 42px;
    min-width: 42px;
    height: 42px;
    line-height: 40px;
    text-align: center;
    margin-right: 10px;
    background-color: $v4_color_primary_light;

    svg {
      width: initial;
      height: 17px;
      margin-left: 1px;
    }
  }

  &--blackfriday {
    .featured-nav-item__left {
      background-color: white;
      svg {
        fill: black !important;
      }
    }
  }

  &--christmas {
    .featured-nav-item__left {
      background-color: white;
      svg {
        fill: $christmas_color_secondary !important;
      }
    }
  }
}

.ofertas-relampago .featured-nav-item__right {
  color: #bc5740;
}

.kits-com-ate-40-off {
  .featured-nav-item__left {
    background-color: #f2fafa;
  }
  .featured-nav-item__right {
    color: #567c7e;
  }
}

@media (min-width: $v4_desktop) {
  .featured-nav-item {
    .featured-nav-item__right {
      min-width: 90px;
      white-space: normal;
      line-height: 1.3;
      text-align: left;
      font-size: 14px;
      font-weight: bold;
      -webkit-line-clamp: 2; /* number of lines to show */
    }
  }
}
</style>
