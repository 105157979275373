var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.messages.length)?_c('div',{class:[
    'topbar',
    {
      'topbar--cart': _vm.isCartPage,
      'topbar--gamification': _vm.gamification && _vm.gamification.gamification,
      'topbar--blackfriday': _vm.$store.state.isBlackFriday,
      'topbar--christmas': _vm.$store.state.isChristmas,
    } ]},[_c('div',{staticClass:"topbar__container container"},[_c('div',{staticClass:"topbar__messages"},[_vm._l((_vm.messages),function(message,idx){return _c('div',{key:idx,class:[
          'topbar__message',
          {
            'topbar__message--gamification':
              _vm.gamification && _vm.gamification.gamification,
          } ],domProps:{"innerHTML":_vm._s(message)}})}),_vm._v(" "),_c('div',{staticClass:"topbar__progress"},[(_vm.gamification.gamification)?_c('promo-event-bar',{attrs:{"progress":_vm.gamification.gamification.achieved}}):_vm._e()],1)],2),_vm._v(" "),_c('ul',{staticClass:"topbar__nav"},[(_vm.$isBrStore)?_c('li',[_c('a',{staticClass:"topbar__nav-link",attrs:{"href":"/go-for-good","aria-label":"Go For Good"}},[_vm._v("\n          Go For Good\n        ")])]):_vm._e(),_vm._v(" "),(_vm.$isBrStore)?_c('li',[_c('a',{staticClass:"topbar__nav-link",attrs:{"href":"https://reseller.gocase.com.br/pt-BR/welcome","target":"_blank","rel":"noopener","aria-label":"Seja Revendedor(a)"}},[_vm._v("\n          "+_vm._s(_vm.$t('header.become_reseller'))+"\n        ")])]):_vm._e(),_vm._v(" "),(_vm.$isBrStore)?_c('li',[_c('a',{staticClass:"topbar__nav-link",attrs:{"href":"https://corporativo.gocase.com.br/","target":"_blank","rel":"noopener","aria-label":"Brindes Corporativos"}},[_vm._v("\n          "+_vm._s(_vm.$t('header.corporate_gifts'))+"\n        ")])]):_vm._e(),_vm._v(" "),_c('li',[_c('a',{staticClass:"topbar__nav-link",attrs:{"href":"/find-order","rel":"noopener","aria-label":"$isGlobalStore ? 'Track Order' : 'Rastrear Pedido'"},on:{"click":_vm.sendTrackOrderClickDesktopToGTM}},[_vm._v("\n          "+_vm._s(_vm.$t('header.track_order'))+"\n        ")])]),_vm._v(" "),_c('li',[_c('a',{staticClass:"topbar__nav-link",attrs:{"href":_vm.helpCenterUrl,"target":"_blank","rel":"noopener","aria-label":"Help Center"}},[_vm._v("\n          "+_vm._s(_vm.$t('help_center'))+"\n        ")])])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }