<template>
  <div class="logo__wrapper">
    <img
      v-if="$store.state.isBlackFriday"
      class="logo__blackfriday"
      src="~/assets/images/bf_gocase_2024.webp"
      alt="Black Friday Gocase"
    />
    <img
      v-else-if="$store.state.isChristmas"
      class="logo__blackfriday"
      src="~/assets/images/logo_christmas.webp"
      alt="Natal Gocase"
    />
    <img
      v-else-if="$isBrStore && $store.state.isGocaseBirth"
      class="logo__blackfriday"
      src="~/assets/images/logo-birth.webp"
      alt="Black Friday Gocase"
    />
    <logo
      v-else
      :class="[
        'logo',
        {
          'logo--small': size == 'small',
          'logo--standard': size == 'standard',
        },
      ]"
    />
    <h1>gocase</h1>
  </div>
</template>

<script>
import logo from '~/components/icons/logo/Logo'

export default {
  components: {
    logo,
  },
  props: {
    size: {
      type: String,
      default: 'standard',
    },
  },
}
</script>

<style lang="scss">
.logo {
  width: auto;
  fill: #005baa;

  &--standard {
    height: 24px;
  }

  &--small {
    height: 25px;
  }

  &__wrapper {
    text-indent: -9999px;
    display: flex;
    align-items: center;
  }

  &__blackfriday {
    height: 50px;
    width: 100%;
    max-width: 108px;
    object-fit: contain;
  }

  @media (max-width: $v4_desktop) {
    &__blackfriday {
      max-width: 150px;
      height: 40px;
      margin-top: 5px;
    }
  }

  &__christmas {
    height: 50px;
    width: 100%;
    object-fit: contain;
  }

  @media (max-width: $v4_desktop) {
    &__christmas {
      max-width: 150px;
      height: 40px;
      margin-top: 5px;
      margin-left: -15px;
    }
  }
}

@media (min-width: 992px) {
  .logo--standard {
    height: 36px;
  }

  .logo--small {
    height: 25px;
  }
}
</style>
