<template>
  <div class="cart-widget">
    <icon-bag />
    <span
      v-if="qty > 0"
      :class="[
        `cart-widget__badge`,
        {
          'cart-widget__badge--blackfriday': $store.state.isBlackFriday,
          'cart-widget__badge--christmas': $store.state.isChristmas,
        },
      ]"
      >{{ qty }}</span
    >
  </div>
</template>

<script>
import IconBag from '~/components/icons/bag/Bag'

export default {
  name: 'CartWidget',
  components: {
    IconBag,
  },
  props: {
    qty: {
      type: Number,
      default: 0,
    },
  },
}
</script>

<style lang="scss">
.cart-widget {
  position: relative;

  .cart-widget__badge {
    background: #f4f9fd;
    border-radius: 10px;
    padding: 3px 5px 4px;
    position: absolute;
    color: $v4_color_blue;
    width: 14px;
    height: 14px;
    font-size: 13px;
    line-height: 15px;
    font-weight: bold;
    bottom: 0;
    right: -5px;
    box-sizing: content-box;
    text-align: center;

    &--blackfriday {
      color: $black_friday_color_primary;
      background: white;
    }

    &--christmas {
      color: $christmas_color_secondary;
      background: white;
    }
  }
}

@media (min-width: $v4_desktop) {
  .cart-widget {
    .cart-widget__badge {
      bottom: 0;
    }
  }
}
</style>
